.contact {
  width: 100%;

  margin-top: 30px;
}

.contact-container {
  text-align: center;
  padding: 30px 349px;
  background-color: #f6f6f6;
}
.cont {
  margin-top: 30px;
}
.contact-form {
  padding: 30px 166px 10px 116px;
  margin-top: 6%;
  margin-bottom: 2%;
  border: 1px solid rgb(227 223 223);
  border-radius: 4px;
  height: auto;
  text-align: left;
}
.contact-form > input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 4px;
}
.cont-h {
  font-size: 36px;
}
.contact-btn {
  background-color: #030441;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 14px 90px;
  /* text-align: center; */
  /* text-decoration: none; */
  /* display: inline-block; */
  font-size: 16px;
  font-weight: 500;
  margin: 28px 10px;
  cursor: pointer;
}
.btn-div {
  text-align: center;
  padding: 30px;
}
.btn-right {
  margin: 0 auto;
}
@media (max-width: 800px) {
  .contact-form {
    padding: 0 0 0 0;
    margin-top: 6%;
    margin-bottom: 2%;
    border: 1px solid rgb(227 223 223);
    border-radius: 4px;
    height: auto;
    text-align: left;
  }
  .contact-container {
    text-align: center;
    padding: 0px 16px 0 11px;
    background-color: #f6f6f6;
  }
  .cont-h {
    font-size: 26px;
  }
  .form-content {
    padding: 7px 18px 13px 16px;
  }
  .contact-abt {
    width: 100%;
    font-size: 16px;
  }
  .contact-form > input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  input[type="tel"],
  select {
    width: 100%;
    /* padding: 12px 20px; */
    /* margin: 8px 0; */
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .contact {
    width: 100%;
    margin-top: 30px;
  }
  .contact-container {
    text-align: center;

    background-color: #f6f6f6;
  }
  .contact-btn {
    background-color: #030441;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 10px 34px;
    font-size: 16px;
    font-weight: 500;
    margin: 28px 10px;
    cursor: pointer;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .contact-container {
    text-align: center;
    padding: 50px 50px;
    background-color: #f6f6f6;
  }
  .contact-form {
    padding: 30px 180px 10px 180px;
    margin-top: 6%;
    margin-bottom: 2%;
    border: 1px solid rgb(227 223 223);
    border-radius: 4px;
    height: auto;
    text-align: left;
  }
  .contact-container {
    text-align: center;
    padding: 34px 0px;
    background-color: #f6f6f6;
  }
}
@media (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
  .contact-container {
    text-align: center;
    /* padding: 30px 349px; */
    background-color: #f6f6f6;
  }
}
@media (max-device-width: 800px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}
