.wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 80px;
}

.box {
  /* background-color: #444; */
  color: rgb(5, 5, 5);
  padding: 0.3em;
}

* {
  box-sizing: border-box;
}
.a {
  /* background-color: purple; */
}
.b {
  /* background-color: blue; */
}

/* Forms */
.applyform {
  /* width: 100% !important; */
  /* /* padding: 10px 60px 6px 26px; */
  padding: 2% 10% 0 4%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.cus_radio {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 14px 0 0px 0px;
  color: rgb(34, 33, 33);
}
.MuiInputBase-root {
  padding: 2px;
  margin-top: 8px;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 14px;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  padding: 13px;
  border: 1px solid #ccc;
}
.jobsinfo {
  background-color: #f1f1f1;
  padding: 2px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  /* justify-content: center; */
}
.jobinfo-head {
  font-size: 22px;
  width: 42px !important;
}
.icn-apply {
  font-size: 42px;
  color: #1675e0;
}
.jobsinfo > div {
  background-color: #f1f1f1;
  width: 100px;
  margin: 0px;
  text-align: left;
}
.info-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 10px;
}
.info-grid-item {
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 0 0 0 8px;
  font-size: 14px;
  text-align: justify;
}

.toolbody {
  display: flex;
  text-align: left;
  /* justify-content: center; */
  align-items: center;
}
.tooltip {
  position: relative;
  /* display: inline-block; */
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: visible;
  width: 220px;
  border: 1px solid black;
  background-color: rgb(255 255 255);
  color: rgb(10, 10, 10);
  text-align: center;
  border-radius: 6px;
  padding: 6px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  /* margin-left: -60px; */
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 10%;
  margin-left: -5px;
  border-width: 10px;
  border-style: dashed;

  border-color: transparent transparent black transparent;
}
.reqrd {
  color: orange;
  font-size: 30px;
}
.oppurtunity {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px;
  gap: 20px;
  /* background-color: #2b17d4;

  margin: 6px 450px;
  border-radius: 28px; */
}
.oppr-icn {
  font-size: 40px;
  color: #030441;
}
.career-h3 {
  font-size: 20x;
  line-height: 42px;
}

.readmoreless {
  /* position: absolute; */
  top: 10%;
  left: 23%;
  width: 100%;
}

.readmoreless-text {
  display: inline;
  width: 100%;
}

.read-or-hide {
  color: rgb(194, 195, 197);
  cursor: pointer;
}
.frm-i {
  font-size: 16px;
  color: rgb(140, 147, 155);
}
/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .career-flex-container {
    flex-direction: column;
    width: 100%;
  }
  .container {
    padding: 10px;
  }
  .career {
    margin: 0 0;
  }
  .info-grid-container {
    display: grid;
    grid-template-columns: auto;
    /* background-color: #2196f3; */
    padding: 10px;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .applyform {
    padding: 5px 0px 0px 0px;
  }
  .career-head-item {
    background-color: #fff;
    /* padding: 5px; */
    flex: 100;
  }
  .career-head-item > h4 {
    font-size: 18px;
  }
  .cus_radio {
    display: inline;
    /* justify-content: center; */
    align-items: center;
    padding: 14px 0 0px 0px;
  }
  .incontainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 10px 0 0 0;
    margin: 0px 20px;
  }
  .upldcard {
    width: 100%;
    height: auto;
  }
  .flex-item {
    margin: 0 auto;
    padding: 7px;
    width: 100%;
  }
  .oppurtunity {
    display: contents;
    justify-content: center;
    text-align: center;
    padding: 20px;
    gap: 20px;
  }
  .oppr-icn {
    font-size: 40px;
    color: #030441;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .upldcard {
    height: 300px;
  }
  .upld {
    text-align: center;
    padding: 50px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
  .applyform {
    padding: 5px 0px 0px 0px;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .upldcard {
    height: 300px;
  }
  .upld {
    text-align: center;
    padding: 60px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 100%;
    gap: 40px;
  }
  .applyform {
    padding: 5px 0px 0px 0px;
  }
}
@media (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
  .upldcard {
    height: 350px;
  }
  .upld {
    text-align: center;
    padding: 30px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
  .applyform {
    padding: 4px 0px 0px 0px;
  }
}
@media (max-device-width: 800px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .upldcard {
    height: 350px;
  }
  .upld {
    text-align: center;
    padding: 50px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 100%;
    gap: 40px;
  }
}
