/* Carousal */
.carousel {
  overflow: hidden;
  width: auto;
  /* border: 5px solid dodgerblue; */
  background-color: white;
  height: 900px;
}
.inner {
  white-space: nowrap;
  transition: transform 0.3s;
  /* background-color: black; */
  position: relative;
}
.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid yellow; */
  width: 100%;
  /* height: 650px; */
  /* background-color: green; */
  color: #fff;
  margin-top: 10px;
  transition: all 0.6s ease-in;
}
/* Indicators Next -- previous buttons */
.indicators {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 96%;
  right: 45%;
  left: 45%;
}
.indicators button {
  height: 20px;
  width: 20px;
  border-radius: 100px;
  cursor: pointer;
  border-style: none;
  background-color: #99f;
  margin: 2px;
}
.indicators > button.active {
  background-color: rgb(247, 71, 73);
  /* color: whitesmoke; */
  height: 20px;
  width: 20px;
  border-radius: 100px;
  cursor: pointer;
  border-style: none;
}
.next {
  margin: -3px 0 0 -3px;
}
.imgresponsive {
  width: 600px;
  /* max-width: 400px; */

  height: 700px;
  /* object-fit: cover; */
}
.carousel-flex {
  display: flex;
  width: 83%;
  justify-content: space-between;
  align-items: center;
}
.carousel-flex-left {
  /* border: 1px solid red; */
  /* text-align: center; */
  /* margin: 0 auto; */
  flex: 70%;
}
.carousel-flex-right {
  /* border: 1px solid rgb(37, 6, 6); */
  /* text-align: right; */
  flex: 30%;
}
@media (max-width: 768px) {
  .indicators {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 94%;
    right: 20%;
    left: 20%;
  }
  .indicators button {
    height: 20px;
    width: 20px;
    border: 1px solid pink;
    /* border-radius: 100px; */
    cursor: pointer;
    border-style: none;
    background-color: #99f;
    margin: 2px;
  }
  .carousel {
    overflow: hidden;
    width: auto;
    /* border: 5px solid dodgerblue; */
    background-color: #ccced1;
    height: 700px;
  }
  .imgresponsive {
    width: 300px;
    /* max-width: 400px; */

    /* height: 700px; */
    /* object-fit: cover; */
  }
  .carousel-flex {
    display: flex;
    flex-direction: column-reverse;
    width: 83%;
    justify-content: space-between;
    align-items: center;
  }
  .carousel-flex-left {
    /* border: 1px solid red; */
    margin: 34px 0px 32px 10px;
  }
  .carousel-flex-right {
    /* border: 1px solid rgb(37, 6, 6); */
    margin: 34px 0px 32px 10px;
  }
  .imgresponsive {
    width: 100%;
    height: auto;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .indicators {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 94%;
    right: 20%;
    left: 20%;
  }
  .indicators button {
    height: 20px;
    width: 20px;
    border: 1px solid pink;
    /* border-radius: 100px; */
    cursor: pointer;
    border-style: none;
    background-color: #99f;
    margin: 2px;
  }
  .carousel {
    overflow: hidden;
    width: auto;
    /* border: 5px solid dodgerblue; */
    background-color: #ccced1;
    height: 700px;
  }
}
@media (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
  .indicators {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 94%;
    right: 20%;
    left: 20%;
  }
  .indicators button {
    height: 20px;
    width: 20px;
    border: 1px solid pink;
    /* border-radius: 100px; */
    cursor: pointer;
    border-style: none;
    background-color: #99f;
    margin: 2px;
  }
  .carousel {
    overflow: hidden;
    width: auto;
    /* border: 5px solid dodgerblue; */
    background-color: #ccced1;
    height: 700px;
  }
}
@media (max-device-width: 800px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .indicators {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 94%;
    right: 20%;
    left: 20%;
  }
  .indicators button {
    height: 20px;
    width: 20px;
    border: 1px solid pink;
    /* border-radius: 100px; */
    cursor: pointer;
    border-style: none;
    background-color: #99f;
    margin: 2px;
  }
  .carousel {
    overflow: hidden;
    width: auto;
    /* border: 5px solid dodgerblue; */
    background-color: #f9fafc;
    height: 700px;
  }
}
