.career-flex-container {
  display: flex;
  /* border: 2px solid red; */
  width: 82%;
  margin: 0 auto;
  /* padding: 10px 0 10px 10px; */
}
.career-div {
  width: 100%;
  display: flex;
  margin: 0 auto;
  border: 2px solid blue;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.career-img {
  width: 60%;
}

/*upload*/

.fileUpload input[type="file"] {
  display: none;
}
.btn {
  /* background-color: #2b00d4;  */
  background-color: #030441;
  border: none;
  border-radius: 4px;
  color: white;

  padding: 13px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin: 4px 10px;
  cursor: pointer;
}
.btn:hover {
  text-decoration: none;
  color: white;
  background-color: #2d3080;
}
.appy-btn {
  /* background-color: #2b00d4; */
  background-color: #030441;
  border: none;
  border-radius: 4px;
  width: 100%;
  color: white;
  /* padding: 13px 270px; */

  padding: 13px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin: 4px 4px 4px 0px;
  cursor: pointer;
}
/* Posted Jobs Career */
/* .career {
  margin: 14px 42px;
} */

.career-flex-item-left {
  margin: 0 auto;
  padding: 10px;
  flex: 50%;
}

.career-flex-item-right {
  flex: 50%;
  margin: 0 auto;
  padding: 10px;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  height: 450px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.car-container {
  gap: 50px;
  padding: 35px 16px;
  height: 360px;
  background-color: #f1f1f1;
}

.flex-item {
  margin: 0 auto;
  padding: 10px;
  width: 100%;
}

.incontainer {
  display: grid;

  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 10px 0 5px 0;
}
.upldcard {
  height: 350px;
}
.upld {
  text-align: center;
  /* padding: 80px; */
}
.cargrid {
  display: flex;
  flex-direction: row;

  text-align: left;
}
.cargrid-item-left {
  background-color: #f1f1f1;
  padding: 10px;
  flex: 30%;
  /* text-align: center; */
}
.cargrid-item-right {
  background-color: #f1f1f1;
  padding: 10px;
  height: 100%;
  flex: 70%;
}
.cargrid-item {
  padding: 5px;
  font-size: 18px;
  text-align: left;
}
.btngrid {
  /* display: flex; */
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  align-items: center;
}
.btn-item-left {
  /* background-color: #f1f1f1; */
  position: sticky;
  padding: 10px;
  flex: 50%;
  float: left;
  /* text-align: center; */
}
.btn-item-right {
  /* position: sticky; */
  padding: 10px;
}
/* Apply Job Posts  */

.apply-flex {
  display: flex;
  flex-direction: row;

  /* text-align: center; */
  justify-content: center;
  align-items: center;
}

.apply-flex-item {
  background-color: #f1f1f1;
  padding: 10px;
  flex: 50%;
}
/* RAdio */
.cust_label {
  display: flex;
  cursor: pointer;
  /* font-weight: 500; */
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}
.cust_label input {
  position: absolute;
  left: -9999px;
}
.cust_label input:checked + span {
  background-color: #d6d6e5;
}
.cust_label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #00005c;
}
.cust_label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 99em;
  transition: 0.25s ease;
}
.cust_label span:hover {
  background-color: #d6d6e5;
}
.cust_label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #808081;
}

.cus_radio {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 13px;
}

.muiinp > input {
  border: none !important;
}
.MuiInputBase-input {
  border: none !important;
}
.oppr-flex {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 30px;
}
.career-icn {
  font-size: 30px;
  color: #030441;
}
.uploadcv {
  width: 60%;
}
.career-banner {
  background-color: #e9eaff;

  height: 300px;
}
.career-banner-content {
  width: 82%;

  padding: 50px 12px 30px 20px;
  display: flex;

  margin-left: 8%;
  align-items: center;
  justify-content: space-between;
}
.opp-in-cotainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .career-flex-container {
    flex-direction: column;
    width: 100%;
  }

  .incontainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 10px 0 5px 0;
  }
  .upldcard {
    height: auto;
    width: 100%;
  }
  .flex-item {
    margin: 0 auto;
    padding: 7px;
    width: 100%;
  }
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 100%;
    height: 384px;
  }
  .car-container {
    gap: 50px;
    padding: 4px 6px;
    height: 312px;
    background-color: #f1f1f1;
  }
  .cargrid-item {
    /* background-color: rgb(212, 34, 34); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 10px;
    font-size: 20px;
    text-align: left;
  }
  .cargrid-item-left {
    background-color: #f1f1f1;
    padding: 10px;
    flex: 30%;
    text-align: left;
    /* text-align: center; */
  }
  .cargrid-item-right {
    background-color: #f1f1f1;
    padding: 10px;
    /* display: flex; */
    flex: 70%;
  }
  .appy-btn {
    background-color: #030441;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 13px 130px;
    /* padding: 13px 28px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin: 0 auto;
    cursor: pointer;
  }
  .career-icn {
    font-size: 20px;
    color: #030441;
  }
  .career-banner {
    background-color: #e9eaff;
    height: auto;
  }
  .career-banner-content {
    width: 82%;
    padding: 50px 12px 30px 20px;
    display: flex;
    margin-left: 8%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
  }
  .upld > h3 {
    font-size: 22px;
  }
  .upld > h4 {
    font-size: 18px;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .upldcard {
    height: 300px;
  }
  .upld {
    text-align: center;
    padding: 50px;
  }
  .incontainer {
    display: grid;

    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 10px 0 5px 0;
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: auto;
    height: 480px;
  }
  .car-container {
    gap: 50px;
    padding: 18px 16px;
    height: 380px;
    background-color: #f1f1f1;
  }
  .cargrid-item {
    background-color: rgb(241, 241, 241);

    padding: 10px;
    font-size: 14px;
    text-align: center;
  }
  .cargrid-item-left {
    background-color: #f1f1f1;
    padding: 10px;
    flex: 30%;
    text-align: left;
    /* text-align: center; */
  }
  .cargrid-item-right {
    background-color: #f1f1f1;
    padding: 10px;
    flex: 70%;
    text-align: left;
  }
}
.career-head {
  display: flex;
  width: 100% !important;
  height: 100px;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: white !important;
}
.career-head-item {
  background-color: #fff;
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .upldcard {
    height: 300px;
  }
  .upld {
    text-align: center;
    padding: 60px;
    width: 100%;
  }
  .cargrid-item {
    background-color: rgb(241 241 241);
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 10px;
    font-size: 10px;
    text-align: left;
  }
  .cargrid-item-left {
    background-color: #f1f1f1;
    padding: 10px;
    flex: 30%;
    text-align: left;
    /* text-align: center; */
  }
  .cargrid-item-right {
    background-color: #f1f1f1;
    padding: 10px;
    flex: 70%;
    text-align: left;
  }
  .btn-apply {
    /* background-color: #2b00d4; */
    background-color: #030441;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 13px 10px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin: 4px 4px 4px 4px;
    cursor: pointer;
  }
  .uploadcv {
    width: 50%;
  }
  .upld > h3 {
    font-size: 24px;
  }
  .career-h3 {
    font-size: 25px;
  }
  .cargrid-item > h4 {
    font-size: 18px;
  }
}
@media (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
  .upldcard {
    height: 350px;
  }
  .upld {
    text-align: center;
    padding: 30px;
    width: 100%;
  }
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: auto;
    height: 312px;
  }
  .car-container {
    gap: 50px;
    padding: 1px 22px;
    height: 228px;
    background-color: #f1f1f1;
  }
  .cargrid-item {
    background-color: rgb(241 241 241);
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 0px;
    font-size: 16px;
    text-align: left;
  }
  .appy-btn {
    background-color: #030441;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 13px 150px;

    text-align: center;
    text-decoration: none;
    align-items: center;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin: 4px 4px 4px 0px;
    cursor: pointer;
  }
  .incontainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;
    padding: 0 0 0px 0;
  }
}
@media (max-device-width: 800px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .upldcard {
    height: 350px;
    width: 100%;
  }
  .upld {
    text-align: center;
    padding: 50px;
    width: 100%;
  }
  .cargrid-item {
    background-color: rgb(
      241 241 241
    ); /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 10px;
    font-size: 10px;
    text-align: center;
  }
  .incontainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 14px;
    padding: 0 0 0px 0;
  }
}
