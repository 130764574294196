form {
  padding: 10px 26px 6px 26px;
  margin-top: 1%;
  margin-bottom: 2%;
  border: 1px solid #e3dede;
  border-radius: 4px;
  height: auto;
}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
select {
  width: 100%;
  padding: 10px 5px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 4px;
}
.box > input + span {
  padding-right: 30px;
  position: absolute;
}
.box > div {
  margin-bottom: 10px;
  position: relative;
}
.box > input:invalid + span:after {
  position: absolute;
  content: "✖";
  padding-left: 5px;
  color: #8b0000;
}

.box > input:valid + span:after {
  position: absolute;
  content: "✓";
  padding-left: 5px;
  color: #009000;
}
select {
  cursor: pointer;
  background-color: none;
}
option {
  cursor: pointer;
}
frm-btn {
  background-color: #04aa6d;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

button:hover {
  opacity: 0.8;
}

.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

img.avatar {
  width: 40%;
  border-radius: 50%;
}

.container {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}
/* .upd-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}
.upd-file {
  width: 100px;
  height: 100px;
  margin: 0 auto;
} */
/* .image-upload > input {
  display: none;
} */
.image-upload > i {
  font-size: 80px;
  color: whitesmoke;
  cursor: pointer;
  /* border: 1px solid black; */
  padding: 5px;
  background-color: #f44336;
  border-radius: 50px;
}
.cls-flex {
  display: flex;
  text-align: center;
  align-items: center;
}
.cls-flex > .div1 {
  text-align: left;
}
.cls-flex > .div2 {
  justify-content: center;
  margin: 0 30%;
}
/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
  .cancelbtn {
    width: 100%;
  }
}
