.banner-flex-container {
  display: flex;
  /* border: 2px solid red; */
  width: 82%;
  margin: 0 auto;
  justify-content: space-between;
  /* padding: 240px 0 240px 0; */
  padding: 116px 0 116px 0;
}

.banner-flex-item-left {
  /* margin: 0 auto; */
  justify-content: space-between;
  padding: 10px;
  flex: 70%;
}

.banner-flex-item-right {
  flex: 30%;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px;
}
.banner-left {
  display: flex;
}
.banner-svg {
  /* border: 1px solid purple; */
  position: absolute;
}

.banner-txt {
  /* border: 1px solid red; */
  position: relative;
  /* font-family: "Iowan Old Style"; */
  font-style: normal;
  font-weight: 400;
  font-size: 60.6935px;
  line-height: 84px;
  top: 50px;
  left: 8%;
  color: #030441;
  font-weight: 650;
}
.heropic {
  width: 480.98px;
  height: 370px;
  float: right;
}
.services-container {
  width: 82%;
  margin: auto;
}
.services {
  width: 100%;
  background-color: #f1f1f1;
  /* border: 1px solid purple; */
}
.about-serv {
  display: flex;
  margin: 10px;
  padding: 23px 0 24px 70px;
  font-size: 20px;
  justify-content: center;
  align-items: center;
}
.service-container {
  display: grid;
  padding: 0px 0 62px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-size: 20px;
  text-align: center;
  /* justify-content: center; */
}
.service-item {
  padding: 12px;

  /* flex: 50%; */
}
.services-txt > h3 {
  text-align: center;
  padding: 20px 72px;
  line-height: 42px;
  font-weight: 600;
  font-size: 30px;
  line-height: 49px;
  color: #030441;
}
.mission-txt {
  padding: 0px 0px 36px 85px;
  font-size: 20px;
}

.staffing-head {
  background-color: #e9eaff;
  height: 300px;
}
.staffing-content {
  width: 82%;
  /* border: 1px solid purple; */
  padding: 50px 12px 30px 20px;
  display: flex;

  margin-left: 8%;
  align-items: center;
  justify-content: space-between;
}
.staff-pic-div {
  width: 450px;
}
.staffpics {
  width: 100%;
}
.perstaff-content {
  width: 72%;
  padding: 30px 30px 30px 20px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-left: 14%;
  margin-top: 80px;
  margin-bottom: 80px;
  align-items: center;
  background-color: white;
}
.phiring-left {
  width: 30%;
}
.phiring-right {
  width: 70%;
}
.phiring-right > p {
  font-size: 18px;
  text-align: justify;
}
.points-txt {
  display: flex;
  align-items: center;
  gap: 20px;
  /* justify-content: center; */
}
@media (max-width: 800px) {
  .banner-flex-container {
    flex-direction: column-reverse;

    width: 100%;
  }
  .banner-flex-container {
    display: flex;
    /* border: 2px solid red; */
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    padding: 5px 0 20px 0;
  }
  .info {
    background-color: #030441;
  }
  .banner-txt {
    /* border: 1px solid red; */
    position: absolute;
    width: 100%;
    text-align: left;
    font-size: 28px;
    top: 30px;
    left: 10%;
    color: #030441;
  }
  .service-container {
    display: grid;
    /* flex-direction: row; */
    grid-template-columns: 1fr;
    font-size: 16px;
    text-align: center;
    /* justify-content: center; */
  }
  .about-serv {
    display: inline;
    margin: 10px;
    padding: 23px 0 24px 70px;
    font-size: 20px;
    justify-content: center;
    align-items: center;
  }

  .serv-img {
    /* border: 1px solid purple; */
    width: 44%;
    min-width: 0;
  }
  .banner-item-left {
    background-color: #f1f1f1;
    padding: 0px;
    /* flex: 30%; */
    text-align: left;
  }
  .banner-item-right {
    background-color: #f1f1f1;
    padding: 0px;
    /* flex: 70%; */
    text-align: left;
  }
  .banner-svg {
    /* border: 1px solid purple; */
    position: absolute;
  }
  .banner-txt {
    font-weight: 400;
    position: relative;
    width: 100%;
    font-size: 48px;
    padding: 0 0 0 0;
    left: 0%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .heropic {
    width: 340px;
    height: auto;
  }
  .services-txt > h3 {
    text-align: center;
    padding: 20px 20px;
    line-height: 42px;
    font-weight: 600;
    font-size: 26px;
    line-height: 49px;
    color: #030441;
  }
  .service-item {
    font-size: 26px;
    padding: 28px 40px;
  }
  .service-item p {
    font-size: 22px;
    padding: 30px 0px 0px;
  }
  .mission-txt {
    padding: 0px 0px 24px 0px;
    font-size: 16px;
    justify-content: center;
    text-align: justify;
  }
  .staffing-head {
    background-color: #e9eaff;
    height: auto;
  }
  .staffing-content {
    padding: 50px 12px 30px 20px;
    display: flex;
    gap: 20%;

    align-items: center;
    justify-content: space-between;
  }
  .staff-pic-div {
    width: 100%;
    padding: 0px;
  }
  .pstaff-txt > h2 {
    font-size: 22px;
    line-height: 34px;
    text-align: left;
  }
  .perstaff-content {
    width: 88%;
    border: 1px solid purple;
    padding: 30px 30px 30px 20px;
    display: contents;
    margin-left: 6%;
    align-items: center;
  }
  .phiring-right {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .phiring-right > p {
    font-size: 16px;
    text-align: justify;
  }
  .phiring-left {
    width: 100%;

    padding: 20px 40px 0px 40px;
  }
  .phiring-right > h3 {
    font-size: 20px;
    /* text-align: justify; */
    padding: 5px;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .banner-flex-container {
    display: flex;
    /* border: 2px solid red; */
    width: 82%;
    margin: 0 auto;
    justify-content: space-between;
    padding: 240px 0 240px 0;
  }
  .phiring-left {
    width: 30%;
  }
  .phiring-right {
    width: 70%;
  }
  .staffpics {
    width: 75%;
    margin-top: 6px;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .banner-flex-container {
    display: flex;
    /* border: 2px solid red; */
    width: 82%;
    margin: 0 auto;
    justify-content: space-between;
    padding: 48px 0 26px 0;
  }
  .banner-txt {
    position: relative;

    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 54px;
    top: 50px;
    left: 8%;
    color: #030441;
    font-weight: 500;
  }
  .phiring-left {
    width: 30%;
  }
  .phiring-right {
    width: 70%;
  }
  .perstaff-content {
    width: 72%;
    padding: 0px 30px 30px 20px;
    display: flex;
    justify-content: space-between;
    gap: 100px;
    margin-left: 14%;
    margin-top: 30px;
    margin-bottom: 80px;
    align-items: center;
    background-color: white;
  }
  .staffing-head {
    background-color: #e9eaff;
    height: 200px;
  }
  .staffing-content {
    width: 82%;
    /* border: 1px solid purple; */
    padding: 0px 12px 30px 20px;
    display: flex;

    margin-left: 8%;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
}
@media (max-device-width: 800px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

@media (min-width: 600px) and (max-width: 800px) {
  .banner-flex-container {
    display: flex;
    /* border: 2px solid red; */
    width: 82%;
    margin: 0 auto;
    justify-content: space-between;
    /* background-color: green; */
    padding: 0px 0 0px 0;
  }
}

@media only screen and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) and (min-device-width: 768px) and (max-device-width: 1007px) {
  .banner-flex-container {
    display: flex;

    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
  }
}
