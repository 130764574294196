.App {
  font-family: sans-serif;
  text-align: center;
}

.overlay-base {
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition-property: background-color, opacity;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-after {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
}

.overlay-before {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
}

.content-base {
  background-color: transparent;
  transition-property: background-color, width, height;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}
.content-after {
  width: 70%;
  height: auto;
  background-color: #ffffff;
}

.content-before {
  width: 0%;
  height: 0%;
  background-color: transparent;
}
.cls {
  padding: 10px;
  width: 10%;
  background-color: #fffdfd;
}
.cls-btn {
  /* padding: 15px 10px 10px 5px;
  margin: 0 0px 360px 0; */
  top: 0;
  margin: 0 auto;
  border: none;
  font-size: 32px;
  color: rgb(247, 71, 73);
  cursor: pointer;
}
.modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}
/* .flex-container {
  display: flex;
  background-color: DodgerBlue;
}

.flex-container > div {
  background-color: #f1f1f1;
  margin: 10px;
  padding: 20px;
  font-size: 30px;
} */
@media screen and (max-width: 300px) {
  .overlay-base {
    padding: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition-property: background-color, opacity;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 600px) {
  .overlay-base {
    padding: 10% 0 0 0;
  }
  .cls-flex > .div2 {
    margin: 0 auto;
    width: 100%;
  }
  form {
    padding: 10px 20px 20px 20px;
    margin-top: 1%;
    width: 100%;
    margin-bottom: 2%;
    border: 1px solid #e3dede;
    border-radius: 4px;
    height: auto;
  }
  body {
    font-size: 12px;
  }
  img {
    /* min-width: 100%; */
    width: 48%;
  }
  .btn-upload {
    background-color: #030441;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 14px 37px;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 12px;
    font-weight: 500;
    margin: 4px 10px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
  }

  .career-head-item {
    background-color: #fff;
    /* padding: 5px; */
  }
}
