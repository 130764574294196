.main-footer {
  padding: 30px 0;
  display: flex;
  justify-content: space-evenly;
}

.main-footer ul {
  list-style: none;
}

.main-footer h1 {
  font-size: 34px;
  line-height: 117%;
  color: #ffffff;
  margin-bottom: 10px;
  font-weight: 500;
}

.main-footer h2 {
  color: #ffffff;
  font-weight: 500;
}

.main-footer ul li a {
  color: #ffffffcc;
  text-decoration: none;
}

.copyrt {
  background-color: #030441;

  font-size: 17px;
  padding: 15px 5px;
  color: #ffffff;
  text-align: center;
}

.main-footer a {
  text-decoration: none;
  color: #ffffff;
}

.logoinfo p {
  color: #6eb981;
  font-size: 17px;
  margin-top: 5px;
}

.contact-details {
  margin-top: 10px;
}

.contact-details li {
  list-style: none;
  margin: 10px 0;
}
.main-footer li:hover,
li:focus-within {
  background: none;
  cursor: pointer;
}
.contact-details li a {
  text-decoration: none;
  color: #f1f1f1;
}

.contact-details .fa {
  color: #f1f1f1;
  margin-right: 10px;
}

.sociallogos {
  padding: 20px 0;
}

.sociallogos .logobox a {
  padding: 0 10px;
  text-decoration: none;
  color: #ffffff;
  font-size: 22px;
}

.com ul li {
  padding: 5px 0;
}
.footer-icn {
  font-size: 18px;
  color: white;
}
@media only screen and (max-width: 749px) {
  .rsv-footer {
    height: auto;
  }
  .main-footer {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .info {
    padding: 20px 0;
  }
  .main-footer h1 {
    font-size: 20px;
    line-height: 117%;
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .main-footer h3 {
    font-size: 20px;
    line-height: 42px;
  }
}
@media (max-width: 800px) {
  .main-footer {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .main-footer > div {
    align-items: center;
    justify-content: center;
  }
  .rsv-footer {
    /* height: 300px; */
    border-radius: 1px solid purple;
    left: 0px;
    background: #030441;
  }
}
@media (max-width: 480px) {
  .main-footer {
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
  }
  .sociallogos {
    padding: 20px 0;
  }
  .copyrt {
    background-color: #030441;
    font-size: 12px;
    padding: 15px 5px;
    color: #ffffff;
    text-align: center;
  }
  .com {
    padding: 20px 0;
  }
}
/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .logoinfo > h3 {
    font-size: 20px;
  }
  .contact-details > h1 {
    font-size: 20px;
  }
  .com > h1 {
    font-size: 20px;
  }
  .info > h1 {
    font-size: 20px;
  }
  .copyrt {
    font-size: 14px;
  }
}
