/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Quicksand:wght@300;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300&family=Quicksand:wght@300;700&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  color: #151515 !important;

  line-height: 1.6;

  margin: 0;

  background-color: #e5e5e5;
}
.navbar,
.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ul {
  margin: 0;
  margin-right: 10%;
  padding: 0;
  list-style: none;
}
h2,
h3,
a {
  color: #030441;
}
.main-nav li > a {
  text-decoration: none;
}
.logo {
  margin: 0;
  font-size: 1.45em;
  padding: 0 9px 9px;
}

.main-nav {
  margin-top: 5px;
}
.logo a,
.main-nav a {
  padding: 10px 12px;
  /* text-transform: uppercase; */
  display: block;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
}

.main-nav a {
  color: #030441;
  font-size: 18px;
  font-weight: 500;
}
.main-nav a:hover {
  color: #718daa;
}
.navbar {
  position: sticky;
  top: 0;
  z-index: 100;

  padding-left: 0.5em;

  height: 80px;

  padding-bottom: 0px;

  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
}

.navbar input[type="checkbox"],
.navbar .hamburger-lines {
  display: none;
}

.rsv-footer {
  /* height: 300px; */
  /* border-radius: 1px solid purple; */
  left: 0px;
  background: #030441;
}
.flex-logo {
  display: flex;
  justify-content: space-between;
  justify-items: start;
  align-items: center;
  text-align: center;
  margin: 0 atuo;
}

.flex-logo > div {
  padding: 20px 0px 10px;
  font-weight: bold;
  font-size: 24px;
}
.logo-text {
  padding-top: 28px !important;
}
.body-content {
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #030441;
  font-size: 1.2rem;
}

.c-list {
  width: 30%;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
}

.centered-content .title {
  display: flex;
}
.uline {
  padding: 5px;
  width: 20%;
  justify-content: center;
  text-align: center;
  border-bottom: 3px solid #000;
  line-height: 0.1em;
  /* margin: 10px 0 20px; */
  margin: auto;
}

.uline span {
  /* background-color: #e5e5e5; */
  background: #e5e5e5;
  padding: 0 10px;
}

.ellipse {
  width: "200px";
}
/* ================================= 
  Nav Sub menu
====================-item================ */
.submenu {
  position: relative;
}
.submenu a {
  text-align: left;
}

li:hover,
li:focus-within {
  background: #e5e5e5;
  cursor: pointer;
}

li:focus-within a {
  outline: none;
}

ul li ul {
  background: #e5e5e5;
  visibility: hidden;
  opacity: 0;
  min-width: 20rem;
  position: absolute;
  transition: all 0.5s ease;
  /* margin-top: 1rem; */
  left: 0;
  display: none;
}

ul li:hover > ul,
ul li:focus-within > ul,
ul li ul:hover,
ul li ul:focus {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
  clear: both;
  width: 100%;
}

/* ================================= 
  Hero 
==================================== */
.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;

  padding: 5px 200px 0 204px;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.flex-container .flex-item {
  color: #030441;
  width: 100%;
  /* margin: 10px; */
  margin-top: 5%;
  text-align: center;
  /* line-height: 75px; */
  font-size: 20px;
  align-content: space-between;
}
.flex-item-left {
  padding: 10px;
  flex: 70%;
  width: 100%;
}

.flex-item-right {
  padding: 10px;
  flex: 30%;
  /* width: 50%; */
}
.hero-text {
  position: relative;
  width: 695.24px;
  top: 50px;
  height: 500px;
  flex-direction: column;
  /* border: 1px solid red; */
}
.hero-ellipse {
  position: absolute;

  width: 140px;
  height: 140px;

  top: 34px;
}
.hero-txt {
  position: absolute;
  width: 100%;
  height: 228px;
  padding: 8px;

  text-align: left;
  top: 56px;

  font-style: normal;
  font-weight: 400;
  font-size: 45.69px;

  /* line-height: 76px; */

  /* letter-spacing: -0.03em; */

  color: #0c003d;
}

.btn-upload {
  background-color: #030441;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 12px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin: 4px 10px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.btn-content {
  display: flex;
}
.btn-content .btn-sub {
  top: 0;
  margin: auto;
  padding: 0 5px;
}
.btn-content .btn-font {
  margin-left: 12px;
}
/* ================================= 
  About us css
==================================== */
.abt-main {
  width: 80%;
  /* border: 1px solid blue; */
  margin: 0 auto;
  font-size: 20px;
  margin-top: 40px;
}

.contact-main {
  /* border: 1px solid blue; */
  margin: 0 auto;
  font-size: 20px;
  margin-top: 40px;
}
.about-section {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 40px 0 24px 0;
}
.about-section .about-abt {
  /* background-color: grey; */
  margin: 10px;

  padding: 20px 0px 0px 0;
  font-size: 20px;
}
.about-abt {
  width: 30%;
}
.abt-text {
  font-size: 2rem;
  color: #030441;
  opacity: 0.3;
}
.rsv-global {
  text-align: left;
  font-size: 50px;

  color: rgb(247, 71, 73);
}
.global {
  font-size: 50px;
  font-weight: normal;
  color: #030441;
}
.abt-subcontent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}
.abt-subcontent-content {
  justify-items: start;
  align-items: center;
  display: grid;
  /* border: 1px solid green; */
  width: 100%;
  grid-template-columns: 380px 380px 380px 380px;
  gap: 10px;

  padding: 44px 0 44px 0;
}
.d {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  /* margin: 0 auto; */
}
.in1 {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  /* margin: 0 auto; */
}
.in2 {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  /* margin: 0 auto; */
}
.ptext {
  display: inline-block;
  padding: 20px 0 20px 0;
}
.responsiveimg {
  width: 100%;
  /* border: 1px solid red; */
  object-fit: contain;
  object-position: bottom;
}
.abt-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 35px;

  padding: 10px;
}

.abt-grid > div {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgb(230, 228, 228);
  text-align: center;
  font-size: 30px;
}
.card-abt {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 300px;
  width: 100%;
}

.card-abt:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.abt-card-container {
  display: flex;
  padding: 2px 16px;
  text-align: center;
  align-items: center;
  margin: 0 auto;
}
.card-top {
  padding: 20px;
}
.myh5 {
  padding: 20px 0 0 0;
}
.myh5 > b {
  text-align: center;
}

/* ================================= 
  Media Queries
==================================== */
@media (max-width: 768px) {
  .navbar {
    opacity: 0.95;

    padding-left: 0.5em;
  }

  .about {
    flex-direction: column;
  }
  .about-item-left {
    padding: 10px;

    width: 100%;
  }
  #about {
    padding: 7% 0 0 0;
  }
  .about-item-right {
    padding: 10px;
    flex: 100%;
    width: 100%;
  }
  .heropic {
    width: 300px;
  }
  .about-section {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1fr;
    justify-content: start;
    align-items: center;
    width: 80%;
  }
  /* border: 1px solid red; */

  .about-section .about-abt {
    width: 100% !important;
    margin: 0 auto;

    padding: 0px 0px 0px 0;
    font-size: 20px;
  }
  .about-abt {
    width: 100%;
  }
  .text {
    /* align-items: center; */
    text-align: justify;
    font-size: 16px;
    display: flex;

    justify-content: center;
    width: 100% !important;
  }
  .abt-main {
    width: 100%;
    /* border: 1px solid blue; */
    margin: 0 auto;
    font-size: 20px;
    margin-top: 10px;
  }

  .abt-subcontent-content {
    justify-items: start;
    align-items: center;
    display: grid;
    /* border: 1px solid green; */
    width: 100%;
    grid-template-columns: 1fr;
    gap: 0;

    padding: 44px 0 44px 0;
  }

  .flex-logo {
    display: flex;
    justify-content: space-between;
    justify-items: start;
    text-align: center;
    align-items: center;
  }
  .logo-text {
    padding: 0;
  }
  .flex-logo > div {
    padding: 0 0 0 0;
  }
  .hero-txt {
    position: absolute;
    left: 0px;
    padding: 0 20px;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 22.69px;
    line-height: 36px;
    color: #0c003d;
    /* top: 33px; */
  }
  .ellipse {
    width: 80px;
  }
  .navbar input[type="checkbox"],
  .navbar .hamburger-lines {
    display: block;
  }

  .navbar {
    display: block;
    /* position: relative; */
    height: 64px;
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .navbar input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .navbar .hamburger-lines {
    display: block;
    height: 28px;
    width: 35px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .flex-item-left {
    padding: 10px;
    width: 400px;
  }
  .responsiveimg {
    /* width: 100%; */
    /* border: 1px solid red; */
    object-fit: contain;
    object-position: bottom;
  }
  .hero-ellipse {
    position: absolute;
    width: 0px;
    height: 115px;
    left: 10px;
    /* top: 12px; */
    top: 12px;
  }

  .navbar .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #333;
  }

  .navbar .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .navbar .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }
  .abt-grid {
    display: grid;

    grid-template-columns: auto;
    gap: 10px;
    /* background-color: #2196f3; */
    padding: 10px;
  }
  .abt-img {
    width: 100%;
    height: 200px;
  }

  .abt-grid > div {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgb(230, 228, 228);

    text-align: center;
    font-size: 30px;
  }

  .navbar .main-nav {
    padding-top: 100px;
    background: #ffffff;
    height: 100vh;
    max-width: 300px;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-left: -40px;
    padding-left: 40px;
    transition: transform 0.5s ease-in-out;
    box-shadow: 5px 0px 10px 0px #aaa;
    overflow: scroll;
  }

  .navbar .main-nav li {
    margin-bottom: 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .logo {
    position: absolute;
    top: 0;
    right: 20%;
    right: 20% !important;
    padding: 0px 30px 0 0;
    font-size: 2rem;
  }
  ul li ul {
    background: #e5e5e5;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    min-width: 13rem;
    position: absolute;
    transition: all 0.5s ease;
    /* margin-top: 1rem; */
    left: 0;
    display: none;
  }

  ul li:hover > ul,
  ul li:focus-within > ul,
  ul li ul:hover,
  ul li ul:focus {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  ul li ul li {
    clear: both;
    width: 100%;
  }

  .navbar input[type="checkbox"]:checked ~ .main-nav {
    transform: translateX(0);
  }

  .navbar input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
}

@media (max-width: 500px) {
  .navbar input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
  .abt-subcontent-content {
    justify-items: start;
    align-items: center;
    /* text-align: justify; */
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    /* gap: 10px; */

    padding: 44px 0 44px 10px;
  }
  .flex-item-left {
    padding: 10px;
    width: 400px;
  }
}
@media screen and (max-width: 936px) {
  .abt-subcontent-content {
    justify-items: start;
    align-items: center;
    /* text-align: justify; */
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    /* gap: 10px; */

    padding: 44px 0 44px 10px;
  }

  .flex-logo {
    display: flex;
    justify-content: space-between;
    justify-items: start;
    text-align: center;
    align-items: center;
  }
  #about {
    padding: 7% 0 0 0;
  }
  .contact-container {
    text-align: center;
    padding: 30px 0;
    background-color: #f6f6f6;
  }
  .about-section {
    display: contents;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 40px 0 24px 0;
  }
  /* .contact-container {
    text-align: center;
    padding: 30px 0;
    background-color: #f6f6f6;
  } */
  .contact-container {
    text-align: center;
    padding: 30px 349px;
    background-color: #f6f6f6;
  }
  .banner-flex-container {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
  }
  .about-serv {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px 0 16px 0 !important;
    font-size: 20px;
    justify-content: center;
    align-items: center;
  }
  .abt-main {
    width: 100%;

    margin: 0 auto;
    font-size: 20px;
    margin-top: 40px;
  }
  .mission-txt {
    padding: 0px 0px 24px 0px;
    font-size: 20px;
  }
  .about-section .about-abt {
    width: 100% !important;
    margin: 0 auto;
    text-align: center;
    padding: 0px 0px 0px 0;
    font-size: 20px;
  }
  .logo-text {
    padding-top: 0 !important;
  }
  .flex-logo > div {
    padding: 0 0 0 0;
  }
  .navbar input[type="checkbox"],
  .navbar .hamburger-lines {
    display: block;
  }
  .flex-item-left {
    padding: 10px;
    width: 100%;
  }

  .navbar {
    display: block;
    /* position: relative; */
    height: 64px;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  .rsv-footer {
    /* height: 300px; */
    border-radius: 1px solid purple;
    left: 0px;
    background: #030441;
  }

  .navbar input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .navbar .hamburger-lines {
    display: block;
    height: 28px;
    width: 35px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navbar .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #333;
  }

  .navbar .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .navbar .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar .main-nav {
    padding-top: 100px;
    background: #ffffff;
    height: 100vh;
    max-width: 300px;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-left: -40px;
    padding-left: 40px;
    transition: transform 0.5s ease-in-out;
    box-shadow: 5px 0px 10px 0px #aaa;
    overflow: scroll;
  }

  .navbar .main-nav li {
    margin-bottom: 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .logo {
    position: absolute;
    top: 0;
    bottom: 0;

    right: 35%;
    font-size: 2rem;
  }

  ul li ul {
    background: #e5e5e5;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    min-width: 13rem;
    position: absolute;
    transition: all 0.5s ease;
    /* margin-top: 1rem; */
    left: 0;
    display: none;
  }

  ul li:hover > ul,
  ul li:focus-within > ul,
  ul li ul:hover,
  ul li ul:focus {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  ul li ul li {
    clear: both;
    width: 100%;
  }

  .navbar input[type="checkbox"]:checked ~ .main-nav {
    transform: translateX(0);
  }

  .navbar input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
}
@media (min-width: 1200px) {
  .navbar input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
  .abt-subcontent-content {
    justify-items: start;
    align-items: center;
    /* text-align: justify; */
    display: grid;
    width: 100% !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* gap: 3; */

    padding: 44px 0 44px 10px;
  }
  #about {
    padding: 3% 0 0 0;
  }
}
@media (max-width: 1204px) {
  .navbar input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
  .flex-container {
    border: 1px solid purple;
    display: flex;
    flex-direction: row;
    font-size: 30px;
    text-align: center;
    /* padding: 10px 128px; */
    padding: 0 0 0 0;

    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .about {
    flex-direction: column;
  }
  .flex-item {
    flex-direction: column;
    width: 100%;

    /* width: 100%; */
  }
  .hero-text {
    /* border: 1px solid purple; */
    position: relative;
    width: 100%;
    top: 0px;
    /* height: 232px; */
    flex-direction: column;
    /* border: 1px solid red; */
  }

  .abt-subcontent-content {
    justify-items: start;
    align-items: center;
    /* text-align: justify; */
    display: grid;
    width: 100% !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;

    padding: 44px 0 44px 10px;
  }
  #about {
    padding: 7% 0 0 0;
  }
}
@media (max-width: 1024px) {
  .navbar input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
  .flex-container {
    flex-direction: column-reverse;
    padding: 0 0 0 0;
  }
  .about {
    flex-direction: column;
  }
  .flex-item {
    flex-direction: column;
    width: 100%;

    /* width: 100%; */
  }
  .abt-subcontent-content {
    justify-items: start;
    align-items: center;
    /* text-align: justify; */
    display: grid;
    width: 100% !important;
    grid-template-columns: 1fr;
    gap: 10px;

    padding: 0px 0 0 0;
  }
  .card-top > img {
    width: 100%;
    height: 200px;
  }
  #about {
    padding: 7% 0 0 0;
  }
  .hero-text {
    left: 0;
  }
  .ellipse {
    width: 74px;
  }
  .hero-text {
    /* font-size: 30px;
     */
    top: 0;
    height: 232px;
  }
}
